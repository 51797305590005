import React from "react";
import Lottie from "react-lottie";
import "./App.css";
import * as animationData from "./lottie.json";

const App: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="App">
      <Lottie options={defaultOptions} />
    </div>
  );
};

export default App;
